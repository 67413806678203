import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';

import {ISettings} from '~/types';
import {IReduxStore} from '~/store';
import {setSettingsAction, resetSettingsAction} from '~/store/settings';
import {resetUsersAction} from '~/store/users';
import SettingsForm from './SettingsForm';
import {useTranslation} from 'react-i18next';

const Settings = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {t} = useTranslation();

  const settings: ISettings = useSelector((state: IReduxStore) => state.settings.data);

  const goBack = () => {
    navigate(-1);
  };

  const onSubmit = (val: ISettings) => {
    dispatch(setSettingsAction(val));
    goBack();
  };

  const resetStorage = () => {
    dispatch(resetSettingsAction());
    dispatch(resetUsersAction());
    goBack();
  };

  return (
    <SettingsForm
      title={t('common.Settings')}
      settings={settings}
      onSubmit={onSubmit}
      onCancel={goBack}
      resetStorage={resetStorage}
    />
  );
};

export default Settings;
