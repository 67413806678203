export enum LangType {
  en = 'en',
  es = 'es',
  ua = 'ua',
  ru = 'ru',
}

export const LANGS = Object.keys(LangType);

export enum LangDirType {
  base = 'base',
}

