import {ImageType, SvgName} from '~/types';
import {
  AirplaneImg,
  CarImg,
  CircleImg,
  DuckImg,
  HomeImg,
  HorseImg,
  KettleImg,
  MushroomImg,
  PineTreeImg,
  StarImg,
} from '~/assets/icons';

export const DEFAULT_IMAGE_TYPE = ImageType.Images;
export const DEFAULT_DISTANCE = 5; // m
export const DEFAULT_IMAGE_HEIGHT = (DEFAULT_DISTANCE / 5) * 7; // mm
export const MIN_DISTANCE = 0.5; // m
export const MAX_DISTANCE = 20; // m
export const DEFAULT_V_COEF = 1;
export const DEFAULT_V_DELTA = 0.1;
export const MIN_V_COEF = 0.1;
export const MAX_V_COEF = 2;
export const DEFAULT_CORRECT_PERCENT = 80; // %
export const MIN_CORRECT_PERCENT = 50; // %
export const MAX_CORRECT_PERCENT = 100; // %
export const MIN_CORRECT_ANSWERS = 7;

export const IMAGES: SvgName[] = [
  {
    name: 'AirplaneImg',
    Svg: AirplaneImg,
  },
  {
    name: 'CarImg',
    Svg: CarImg,
  },
  {
    name: 'CircleImg',
    Svg: CircleImg,
  },
  {
    name: 'DuckImg',
    Svg: DuckImg,
  },
  {
    name: 'HomeImg',
    Svg: HomeImg,
  },
  {
    name: 'HorseImg',
    Svg: HorseImg,
  },
  {
    name: 'KettleImg',
    Svg: KettleImg,
  },
  {
    name: 'MushroomImg',
    Svg: MushroomImg,
  },
  {
    name: 'PineTreeImg',
    Svg: PineTreeImg,
  },
  {
    name: 'StarImg',
    Svg: StarImg,
  },
  // AirplaneImg,
  // CarImg,
  // CircleImg,
  // DuckImg,
  // HomeImg,
  // HorseImg,
  // KettleImg,
  // MushroomImg,
  // PineTreeImg,
  // StarImg,
];
export const DEFAULT_IMAGE: SvgName = IMAGES[0];
