import cn from 'classnames';
import React from 'react';

import {DEFAULT_IMAGE_HEIGHT} from '~/constants';

import './checkImages.scss';

interface ICImage {
  width: string | number;
  rotate?: number;
  color?: string;
  className?: string;
}

const CImage = ({
  width = `${DEFAULT_IMAGE_HEIGHT}mm`,
  rotate = 0,
  color = '#000000',
  className,
}: ICImage): JSX.Element => (
  <div
    className={cn('c-image-wrapper', className)}
    style={{
      width: width,
      height: width,
      backgroundColor: color,
      transform: `rotate(${rotate}deg)`,
    }}
  >
    <div className="c-image-inner-circle" />
    <div className="c-image-light-line" />
  </div>
);

export default CImage;
