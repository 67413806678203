import {DEFAULT_V_COEF} from '~/constants';
import {ImageType} from '~/types';

export interface INewUser {
  name: string;
  defaultVCoef: number;
  imageType: ImageType;
}

export interface IUser extends INewUser {
  id: string;
}

export const DEFAULT_NEW_USER: INewUser = {
  name: '',
  defaultVCoef: DEFAULT_V_COEF,
  imageType: ImageType.Images,
};
