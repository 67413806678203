import {Reducer} from 'redux';

import {IUsersStatistics, IUserStatistics} from '~/types';
import {takeEveryDefault} from '~/store/common';
import {statisticsStorage} from '~/services/localStorage';

// Actions
export enum StatisticsActions {
  GET_STATISTICS = 'statistics/GET_STATISTICS',
  SET_STATISTICS = 'statistics/SET_STATISTICS',
  ADD_USER_STATISTICS = 'statistics/ADD_USER_STATISTICS',
  REMOVE_USER_STATISTICS = 'statistics/REMOVE_USER_STATISTICS',
  RESET = 'statistics/RESET',
}

export type StatisticsLoadableT =
  | typeof StatisticsActions.GET_STATISTICS
  | typeof StatisticsActions.SET_STATISTICS
  | typeof StatisticsActions.ADD_USER_STATISTICS
  | typeof StatisticsActions.REMOVE_USER_STATISTICS
  | typeof StatisticsActions.RESET;

export interface IGetStatisticsAction {
  type: typeof StatisticsActions.GET_STATISTICS;
}

export interface ISetStatisticsAction {
  type: typeof StatisticsActions.SET_STATISTICS;
  payload: IUsersStatistics;
}

export interface IAddUserStatisticsAction {
  type: typeof StatisticsActions.ADD_USER_STATISTICS;
  payload: IUserStatistics;
}

export interface IRemoveUserStatisticsAction {
  type: typeof StatisticsActions.REMOVE_USER_STATISTICS;
  payload: string;
}

interface IResetStatisticsAction {
  type: typeof StatisticsActions.RESET;
}

type StatisticsActionsT =
  | IGetStatisticsAction
  | ISetStatisticsAction
  | IResetStatisticsAction
  | IAddUserStatisticsAction
  | IRemoveUserStatisticsAction;

export const getStatisticsAction = (): IGetStatisticsAction => ({type: StatisticsActions.GET_STATISTICS});

export const setStatisticsAction = (payload: IUsersStatistics): ISetStatisticsAction => ({
  type: StatisticsActions.SET_STATISTICS,
  payload,
});

export const addUserStatisticsAction = (payload: IUserStatistics): IAddUserStatisticsAction => ({
  type: StatisticsActions.ADD_USER_STATISTICS,
  payload,
});

export const removeUserStatisticsAction = (payload: string): IRemoveUserStatisticsAction => ({
  type: StatisticsActions.REMOVE_USER_STATISTICS,
  payload,
});

export const resetStatisticsAction = (): IResetStatisticsAction => ({type: StatisticsActions.RESET});

// Sagas
export function* sagas(): Generator {
  yield takeEveryDefault<null, Maybe<IUsersStatistics>>(
    StatisticsActions.GET_STATISTICS,
    statisticsStorage.getStatistics,
    setStatisticsAction,
  );
  yield takeEveryDefault<IUsersStatistics, void>(
    StatisticsActions.SET_STATISTICS,
    statisticsStorage.setStatistics,
  );
  yield takeEveryDefault<IUserStatistics, void>(
    StatisticsActions.ADD_USER_STATISTICS,
    statisticsStorage.addUserStatistics,
  );
  yield takeEveryDefault<string, void>(
    StatisticsActions.REMOVE_USER_STATISTICS,
    statisticsStorage.removeUserStatistics,
  );
  yield takeEveryDefault<null, void>(StatisticsActions.RESET, statisticsStorage.resetStatistics);
}

// Reducer
export interface IUsersStatisticsState {
  data: IUserStatistics[];
}

export type StatisticsStateT = Readonly<IUsersStatisticsState>;

const initialState: IUsersStatisticsState = {data: []};

const reducer: Reducer<StatisticsStateT> = (
  state: IUsersStatisticsState = initialState,
  action: StatisticsActionsT,
) => {
  switch (action.type) {
    case StatisticsActions.SET_STATISTICS:
      return {
        ...state,
        data: action.payload?.usersData || [],
      };

    case StatisticsActions.ADD_USER_STATISTICS: {
      const statisticsData: IUserStatistics[] = state.data;
      const idx = statisticsData.findIndex((val) => val.userId === action.payload.userId);
      let newStatisticsData: IUserStatistics[] = [];
      if (idx !== -1) {
        newStatisticsData = [
          ...statisticsData.slice(0, idx),
          action.payload,
          ...statisticsData.slice(idx + 1),
        ];
      } else {
        newStatisticsData = [...statisticsData, action.payload];
      }
      return {data: newStatisticsData};
    }

    case StatisticsActions.REMOVE_USER_STATISTICS: {
      const statisticsData: IUserStatistics[] = state.data;
      const idx = statisticsData.findIndex((val) => val.userId === action.payload);
      let newStatisticsData: IUserStatistics[] = [];
      if (idx !== -1) {
        newStatisticsData = [...statisticsData.slice(0, idx), ...statisticsData.slice(idx + 1)];
      }
      return {data: newStatisticsData};
    }

    case StatisticsActions.RESET:
      return initialState;

    default:
      return state;
  }
};

export default reducer;
