import {IUsersStatistics, IUserStatistics, DEFAULT_STATISTICS} from '~/types';
import {baseStorage} from '.';

const SETTINGS_STORAGE_KEY = 'checkVision:statistics';

export const getStatistics = async(): Promise<Maybe<IUsersStatistics>> => {
  const res: string = await baseStorage.get(SETTINGS_STORAGE_KEY);
  if (res) {
    const data: IUsersStatistics = JSON.parse(res) || DEFAULT_STATISTICS;
    return data;
  }
  return null;
};

export async function setStatistics(value: IUsersStatistics) {
  const data = JSON.stringify(value);
  await baseStorage.set(SETTINGS_STORAGE_KEY, data);
}

export const addUserStatistics = async(value: IUserStatistics) => {
  const oldData: IUsersStatistics = (await getStatistics()) || DEFAULT_STATISTICS;
  const usersData = oldData.usersData || [];
  const idx = usersData.findIndex((val) => val.userId === value.userId);
  let newUsersData: IUsersStatistics = DEFAULT_STATISTICS;
  if (idx !== -1) {
    newUsersData = {usersData: [...oldData.usersData.slice(0, idx), value, ...oldData.usersData.slice(idx + 1)]};
  } else {
    newUsersData = {usersData: [...oldData.usersData, value]};
  }
  await setStatistics(newUsersData);
};

export const removeUserStatistics = async(userId: string) => {
  const oldData: IUsersStatistics = (await getStatistics()) || DEFAULT_STATISTICS;
  const usersData = oldData.usersData || [];
  const idx = usersData.findIndex((val) => val.userId === userId);
  const newUsersData: IUsersStatistics = {usersData: [...oldData.usersData.slice(0, idx), ...oldData.usersData.slice(idx + 1)]};
  await setStatistics(newUsersData);
};

export const resetStatistics = async() => {
  await baseStorage.reset(SETTINGS_STORAGE_KEY);
};

export default {
  getStatistics,
  setStatistics,
  addUserStatistics,
  removeUserStatistics,
  resetStatistics,
};
