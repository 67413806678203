import BaseApi from './BaseApi';
import ExampleApi from './ExampleApi';

export interface IApiServices {
  baseApi: BaseApi,
  exampleApi: ExampleApi,
}

export function initApiServices(baseUrl?: string): IApiServices {
  const baseApi = new BaseApi(baseUrl);
  const exampleApi = new ExampleApi(baseApi);

  return {
    baseApi,
    exampleApi
  };
}