import sortBy from 'lodash/sortBy';

import {ILabelValue, IStatistic} from '~/types';
import {
  DEFAULT_DISTANCE,
  DEFAULT_IMAGE_HEIGHT,
  DEFAULT_CORRECT_PERCENT,
  MIN_CORRECT_ANSWERS,
} from '~/constants';

// eslint-disable-next-line
export const getOptions = (arr: any[], valueProp = 'id', labelProp = 'name') => {
  const res: ILabelValue<string>[] = arr
    .map((val) => ({
      value: val[valueProp],
      label: val[labelProp] || '',
    }))
    .filter((val) => val.value);
  return res;
};

export const getImageHeight = (distance: number, koef = 1) =>
  Math.round((((DEFAULT_IMAGE_HEIGHT / DEFAULT_DISTANCE) * distance) / koef) * 10) / 10;

export const getStatisticCorrectIdx = (
  arr: IStatistic[] = [],
  percent: number = DEFAULT_CORRECT_PERCENT,
  minCorrectAnswers: number = MIN_CORRECT_ANSWERS,
) => {
  const sortedArr: IStatistic[] = sortBy(arr, (val) => -val.vCoef);
  const len = sortedArr.length;
  const propKPercent = percent / 100;
  for (let i = 0; i < len; i += 1) {
    const corrects = sortedArr[i].corrects;
    const pPercent = corrects / (corrects + arr[i].errors);
    if (pPercent >= propKPercent && corrects < minCorrectAnswers) {
      return {
        row: i,
        error: -2
      };
    }
    if (pPercent >= propKPercent && corrects >= minCorrectAnswers) {
      return {
        row: i,
        error: null
      };
    }
  }
  return {
    row: null,
    error: -1
  };
};

export const checkStatisticForEnd = (
  arr: IStatistic[] = [],
  percent: number = DEFAULT_CORRECT_PERCENT,
  minCorrectAnswers: number = MIN_CORRECT_ANSWERS,
) => {
  const {error} = getStatisticCorrectIdx(arr, percent, minCorrectAnswers);

  return !error;
};

export const getStatisticRate = (
  arr: IStatistic[] = [],
  percent = DEFAULT_CORRECT_PERCENT,
  minCorrectAnswers = MIN_CORRECT_ANSWERS,
) => {
  const sortedArr = sortBy(arr, (val) => -val.vCoef);
  const len = sortedArr.length;
  const propKPercent = percent / 100;
  let vRate = 0.1;
  let vRateIdx = 0;
  for (let i = 0; i < len; i += 1) {
    const corrects = sortedArr[i].corrects;
    const pPercent = corrects / (corrects + arr[i].errors);
    if (pPercent >= propKPercent && corrects >= minCorrectAnswers) {
      vRate = sortedArr[i].vCoef;
      vRateIdx = i;
    }
  }

  if (vRateIdx < len - 1) {
    const nextStat = sortedArr[vRateIdx + 1];
    if (nextStat.corrects) {
      const vPlus = nextStat.corrects / (nextStat.corrects + nextStat.errors);
      vRate += vPlus;
    }
  }

  return vRate;
};
