import React, {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';

import {IReduxStore} from '~/store';
import {IUser, INewUser} from '~/types';
import {setUsersAction, setCurrentUserAction} from '~/store/users';
import UserProfileForm from './UserProfileForm';
import {useTranslation} from 'react-i18next';

const UserProfileEdit = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {t} = useTranslation();

  const users: IUser[] = useSelector((state: IReduxStore) => state.users.data);
  const currentUser: Maybe<IUser> = useSelector((state: IReduxStore) => state.users.current);

  useEffect(() => {
    if (!currentUser && users && window) {
      const path = window.location.pathname;
      const pathArr = path.split('/');
      const editIdx = pathArr.findIndex((val) => val === 'edit');
      if (editIdx !== -1 && editIdx > 0) {
        const idx = editIdx - 1;
        const newCurrentUser = users.find((val) => val.id === pathArr[idx]);
        if (newCurrentUser) {
          dispatch(setCurrentUserAction(newCurrentUser));
        }
      }
    }
  }, []);

  const goBack = () => {
    navigate(-1);
  };

  const onSubmit = (val: INewUser) => {
    const idx = users.findIndex((user) => user.id === currentUser?.id);
    if (idx !== -1 && currentUser) {
      const newUser: IUser = {
        id: currentUser.id,
        ...val,
      };
      const newUsers: IUser[] = [...users.slice(0, idx), newUser, ...users.slice(idx + 1)];
      dispatch(setUsersAction(newUsers));
      dispatch(setCurrentUserAction(newUser));
      goBack();
    }
  };

  const onRemove = () => {
    const idx = users.findIndex((user) => user.id === currentUser?.id);
    if (idx !== -1 && currentUser) {
      const newUsers: IUser[] = [...users.slice(0, idx), ...users.slice(idx + 1)];
      dispatch(setUsersAction(newUsers));
      dispatch(setCurrentUserAction(newUsers[0] || null));
      goBack();
    }
  };

  return (
    <UserProfileForm
      title={t('btn.Edit_User')}
      user={currentUser}
      onSubmit={onSubmit}
      onCancel={goBack}
      onRemove={onRemove}
    />
  );
};

export default UserProfileEdit;
