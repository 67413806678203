export enum ImageType {
  Images = 'Images',
  CType = 'CType',
  EType = 'EType',
}

export enum ImageRotateType {
  'D0' = '0deg',
  'D90' = '90deg',
  'D180' = '180deg',
  'D270' = '270deg',
}

