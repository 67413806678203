import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import {initReactI18next, useTranslation} from 'react-i18next';

import {LangType, LangDirType} from '~/types';
import {locales, ILocales} from '.';

// i18n.on('languageChanged', function(lng) {
//   // if the language we switched to is the default language we need to remove the /en from URL
//   const fallbackLng = i18n?.options?.fallbackLng;
//   // eslint-disable-next-line @typescript-eslint/ban-ts-comment
//   // @ts-ignore
//   const fallbackLng0 = fallbackLng ? fallbackLng[0] : null;
//   console.log('fallbackLng13', fallbackLng);
//   if (lng === fallbackLng0) {
//     const pathname = window?.location?.pathname;
//     console.log('pathname16', pathname);
//     if (pathname?.includes('/' + fallbackLng0)) {
//       const newUrl = pathname.replace('/' + fallbackLng0, '');
//       window.location.replace(newUrl);
//     }
//   }
// });

// import { useTranslation } from 'react-i18next'
// const LanguageSelector = () => {
//   const { t, i18n } = useTranslation()
//   const changeLanguage = (event) => {
//     i18n.changeLanguage(event.target.value)
//   }
// }

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: LangType.en,
    backend: {
      /* translation file path */
      loadPath: '/resources/i18n/{{ns}}/{{lng}}.json',
    },
    // whitelist: [LangType.en, LangType.ua],
    fallbackLng: LangType.en,
    // detection: {
    //   order: ['path'],
    //   lookupFromPathIndex: 0,
    //   checkWhitelist: true
    // },
    debug: true,
    /* can have multiple namespace, in case you want to divide a huge translation into smaller pieces and load them on demand */
    ns: [LangDirType.base],
    defaultNS: LangDirType.base,
    // keySeparator: false,
    interpolation: {
      escapeValue: false,
      formatSeparator: ',',
    },
    react: {wait: true},
  });

export default i18n;

export const useTranslate = () => {
  const t = (str: string) => {
    const {t: t18n} = useTranslation();
    let res = t18n(str);
    if (res.includes('_') && res.includes('.')) {
      const testArr = res.split('_');
      if (testArr.length > 1 || res.length < 15) {
        const arr: string[] = res.split('.');
        if (arr.length > 1) {
          res = arr.join('.');
          res = res.replaceAll('_', ' ');
        }
      }
    }
    return res;
  };
  return {t};
};

export const getLocales = (lang: Maybe<LangType> = LangType.en, str: string) => {
  const data: ILocales[] = locales || [];
  let res = '';
  const findedObj = data.find((val) => val.lang === lang);
  if (findedObj) {
    const findedRes = findedObj.data[str];
    res = findedRes || str;
  } else {
    res = str.replaceAll('_', ' ');
  }
  return res;
};

