import React from 'react';
import cn from 'classnames';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import {useTranslation} from 'react-i18next';
import {
  CreditCardIcon,
  SettingsIcon,
  PersonAddIcon,
  PersonIcon,
  AirplaneImg,
  EyeLeftIcon,
  EyeRightIcon,
  ArrowRightIcon,
  DoneIcon,
  CloseIcon,
  ReturnLeftIcon,
  ReplayIcon,
  DoneAllIcon,
  BarChartIcon,
  FullScreenIcon,
  FullScreenExitIcon,
  VisibilityIcon,
} from '~/assets/icons';
import {CImage, EImage, SvgButton} from '~/components/ui';

import './info.scss';

const Info = () => {
  const {t} = useTranslation();

  return (
    <div className="info-wrapper">
      <Typography variant="h5">{t('Info.Check_Vision_Info')}</Typography>
      <Typography variant="h6">{t('Info.Check_vision_on_a_computer')}.</Typography>
      <Typography variant="body2">
        {t('Info.This_vision_test_is_in_no_way_a_substitute')}.
      </Typography>
      <Typography variant="body2">{t('Info.You_have_to_consult')}.</Typography>
      <Divider className="divider" />
      <div className="page-img-1" />
      <Divider className="divider" />
      <Typography variant="h6">{t('Info.Steps_for_test_vision')}</Typography>
      <Typography variant="body1">1. {t('Info.Browser_has_to_show_correct')}</Typography>
      <Typography variant="body2">{t('Info.Check_size_using_ruler')}</Typography>
      <div className="ten-mm-wrapper">
        <div className="ten-mm" />
        <div className="ten-mm-text">10mm x 10mm</div>
      </div>
      <Typography variant="body2">{t('Info.The_size_of_most_credit_cards')}</Typography>
      <div className="credit-card-wrapper">
        <CreditCardIcon />
      </div>
      <Typography variant="body2" color="primary">
        ! {t('Info.You_can_adjust_your_screen')}:
      </Typography>
      <Typography variant="body2" color="primary">
        Windows PC: the Ctrl and + or - keys
      </Typography>
      <Typography variant="body2" color="primary">
        Mac: Command and + or - keys
      </Typography>
      <Divider className="divider" />
      <Typography variant="body1">2. {t('Info.Create_user')}</Typography>
      <div className="row">
        {t('Info.Press_ADD_USER')} <PersonAddIcon width={20} height={20} className="text-icon" /> /
        <PersonIcon width={20} height={20} className="text-icon" />
      </div>
      <div className="row">{t('Info.and_enter_name')}.</div>
      <div className="row-with-icons">
        <Typography variant="body1" color="primary">
          Image Types: Images
        </Typography>
        <AirplaneImg width={16} height={16} fill={'#000'} className="text-icon" />
        <Typography variant="body1" color="primary">
          , C Type
        </Typography>
        <CImage width={16} className="text-icon" />
        <Typography variant="body1" color="primary">
          , E Type
        </Typography>
        <EImage width={16} className="text-icon" />
      </div>
      <Typography variant="body1">{t('Info.Default_Visual_Coefficient_show_quality')}.</Typography>
      <Typography variant="body2">{t('Info.Visual_Coefficient_could_be_changed')}.</Typography>
      <Typography variant="caption">{t('Info.Any_user_could_be_removed')}.</Typography>
      <Divider className="divider" />
      <Typography variant="body1">3. {t('Info.Set_Distance_and_Allowable_Percentage')}</Typography>
      <div className="row">
        {t('Info.Press_common_settings')}{' '}
        <SettingsIcon width={20} height={20} className="text-icon" />
        {t('Info.icon_for_editing_Distance')}.
      </div>
      <Typography variant="body2">{t('Info.eg_80_means')}</Typography>
      <Typography variant="body1" color="primary">
        {t('Info.Minimum_allowed_correct_answers')}.
      </Typography>
      <Typography variant="body2">{t('Info.For_example_for_percentage')}:</Typography>
      <Typography variant="body2">{t('Info.Correct_answers_8')}.</Typography>
      <Typography variant="body2">{t('Info.Correct_answers_10')}.</Typography>
      <Typography variant="body2">{t('Info.Correct_answers_5')}.</Typography>
      <Divider className="divider" />
      <Typography variant="body1">4. {t('Info.Start_testing')}</Typography>
      <div className="row-with-icons">
        {t('Info.For_starting_press_START')}
        <VisibilityIcon width={20} height={20} className="text-icon" />
      </div>
      <div className="row-with-icons">
        1) {t('Info.For_switch_between_full_screen')}
        <FullScreenIcon width={20} height={20} className="text-icon" /> /
        <FullScreenExitIcon width={20} height={20} className="text-icon" />
        {t('Info.buttons_in_bottom')}.
      </div>
      <div className="row-with-icons">
        2) {t('Info.Select_Left_Eye')}
        <EyeLeftIcon width={20} height={20} className="text-icon" />, {t('Info.or_Right_Eye')}
        <EyeRightIcon width={20} height={20} className="text-icon" />
      </div>
      <div className="row-with-icons">
        3) {t('Info.Select_Image_Size')}
        <SvgButton
          Image={ArrowRightIcon}
          fill="#555"
          width={20}
          onClick={() => null}
          className={cn('rotate90', 'svg-btn-icon')}
        />
        /
        <SvgButton
          Image={ArrowRightIcon}
          fill="#555"
          width={20}
          onClick={() => null}
          className={cn('rotate270', 'svg-btn-icon')}
        />
      </div>
      4) {t('Info.Check_Vision')}.
      <div className="row-with-icons">
        {t('Info.For_correct_answer_use')}
        <DoneIcon width={20} height={20} className="text-icon" />, {t('Info.for_failed')}
        <CloseIcon width={20} height={20} className="text-icon" />
        {t('Info.To_skip_an_image')}
        <ArrowRightIcon width={20} height={20} className="text-icon" /> {t('Info.button')}
      </div>
      <div className="row-with-icons">
        5) {t('Info.For_Image_Type')}
        <ReturnLeftIcon width={20} height={20} className="text-icon" /> {t('Info.button')}.
      </div>
      <div className="row-with-icons">
        6) {t('Info.For_clearing_current')}
        <ReplayIcon width={20} height={20} className="text-icon" />
      </div>
      <Divider className="divider" />
      <Typography variant="body1">5. End testing</Typography>
      <Typography variant="body1" color="primary">
        {t('Info.If_for_both_eyes')}.
      </Typography>
      <div className="row-with-icons">
        {t('Info.After_press')}
        <DoneAllIcon width={20} height={20} className="text-icon" />
        {t('Info.button_confirm_modal')}.
      </div>
      <Divider className="divider" />
      <Typography variant="body1">6. {t('Info.Watch_statistics')}</Typography>
      <div className="row-with-icons">
        {t('Info.For_watch_statistics')}
        <BarChartIcon width={20} height={20} className="text-icon" />
        {t('Info.button')}.
      </div>
      <Typography variant="caption">{t('Info.All_statistics_could')}.</Typography>
      <Divider className="divider" />
      <div className="page-img-c" />
    </div>
  );
};

export default Info;
