import React, {useEffect, Fragment} from 'react';
import Modal from '@material-ui/core/Modal';
import classNames from 'classnames';

import './modal.scss';

export enum ModalStyleType {
  WHITE_LG = 'WHITE_LG',
  BLACK_LG = 'BLACK_LG',
}

interface IOwnProps {
  onClose: () => void;
  isModalOpen: boolean;
  isHideCross?: boolean;
  className?: string;
  classNameInside?: string;
  styleType?: ModalStyleType;
  children: JSX.Element
}

type ModalBodyProps = IOwnProps;

const ModalBody = ({onClose, classNameInside, isModalOpen, className, styleType, children}:ModalBodyProps): JSX.Element => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = '';
    };
  }, []);

  return (
    <Modal
      open={isModalOpen}
      className={`modal-block-container ${isModalOpen ? 'show' : ''} ${
        className ? className : ''
      }`}
      onClose={onClose}
    >
      <Fragment>
        <div
          className={classNames(`modal-block ${classNameInside || ''}`, {'modal-white-bg': styleType === ModalStyleType.WHITE_LG})}
        >
          {children}
          <div
            role="button"
            onClick={onClose}
            onKeyDown={onClose}
            tabIndex={0}
            className={classNames('modal-close-icon', {'modal-white-bg': styleType === ModalStyleType.WHITE_LG})}
          />
        </div>
      </Fragment>
    </Modal>
  );
};

export default ModalBody;