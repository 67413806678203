import cn from 'classnames';
import React, {Fragment} from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import Divider from '@material-ui/core/Divider';

import {ICheckSideBtn, ImageType, SvgName} from '~/types';
import {SvgButton, EImage, CImage} from '~/components/ui';
import {withFullScreen} from '~/components/blocks';
import './check.scss';

export interface ICheckView {
  imageType: ImageType;
  imageSize: number;
  vCoef: number;
  image?: SvgName;
  sideBtns: ICheckSideBtn[][];
  onDoneClick: () => void;
  onNextClick: () => void;
  rotate?: number;
  isImageLast?: boolean;
}

const CheckView = ({
  imageType,
  imageSize,
  vCoef,
  image,
  sideBtns = [],
  rotate = 0,
}: ICheckView): JSX.Element => {
  const width = `${imageSize}mm`;

  const renderImage = () => {
    if (imageType === ImageType.Images && image) {
      const Image = image.Svg;
      return <Image fill="black" width={width} height={width} />;
    }
    if (imageType === ImageType.CType) {
      return <CImage width={width} rotate={rotate} />;
    }
    return <EImage width={width} rotate={rotate} />;
  };

  return (
    <div className="check-wrapper">
      <div className="check-img-wrapper">{renderImage()}</div>
      <div className="check-left-side-bar-wrapper">
        <div className="check-left-side-bar">
          <div>{`${imageSize}mm`}</div>
          <div>{`V=${vCoef}`}</div>
          <Divider variant="fullWidth" />
          {sideBtns.map((section, idx) => (
            <Fragment key={idx}>
              {section.map((val) => (
                <Tooltip title={val.name} placement="bottom" key={val.name}>
                  <SvgButton
                    Image={val.Svg}
                    fill="#ccc"
                    width={40}
                    onClick={val.onClick}
                    isOutlined
                    disabled={val.isDisabled}
                    className={cn(val.className, {'active-icon': val.isActive})}
                  />
                </Tooltip>
              ))}
              <Divider variant="fullWidth" />
            </Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default withFullScreen(CheckView);
