import {DEFAULT_SETTINGS, ISettings} from '~/types';
import {baseStorage} from '.';

const SETTINGS_STORAGE_KEY = 'checkVision:settings';

export const getSettings = async(): Promise<Maybe<ISettings>> => {
  const res: string = await baseStorage.get(SETTINGS_STORAGE_KEY);
  if (res) {
    const data: ISettings = JSON.parse(res);
    return data;
  }
  return null;
};

export async function setSettings(value: ISettings) {
  const data = JSON.stringify({
    ...DEFAULT_SETTINGS,
    ...value
  });
  await baseStorage.set(SETTINGS_STORAGE_KEY, data);
}

export const addSettingsValue = async(value: Partial<ISettings>) => {
  const oldData: ISettings = await getSettings() || DEFAULT_SETTINGS;
  const newData: ISettings = {
    ...oldData,
    ...value,
  };
  await setSettings(newData);
};

export const resetSettings = async() => {
  await baseStorage.reset(SETTINGS_STORAGE_KEY);
};

export default {
  getSettings,
  setSettings,
  addSettingsValue,
  resetSettings,
};
