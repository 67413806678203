import cn from 'classnames';
import React, {useState, useEffect} from 'react';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import {useNavigate} from 'react-router-dom';

import {ILabelValue} from '~/types';
import {PersonIcon, SettingsIcon, PersonAddIcon} from '~/assets/icons';
import './selectUser.scss';
import {ROUTES} from '~/constants';
import {useTranslation} from 'react-i18next';

interface ISelectUser {
  current: Maybe<ILabelValue<string>>;
  list: ILabelValue<string>[];
  onUserSelect?: (val: ILabelValue<string>) => void;
  onSettings: (val: ILabelValue<string>) => void;
  onAdd: () => void;
}

const SelectUser = ({list, current, onUserSelect, onAdd, onSettings}: ISelectUser): JSX.Element => {
  const navigate = useNavigate();
  const {t} = useTranslation();

  // eslint-disable-next-line
  const [anchorEl, setAnchorEl] = useState<any>(null);

  useEffect(() => {
    return () => setAnchorEl(null);
  }, []);
  // eslint-disable-next-line
  const handleMenuClick = (event: any) => {
    if (current) {
      setAnchorEl(event.target);
    } else {
      navigate(ROUTES.PROFILE_ADD);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleUserSelect = (val: ILabelValue<string>) => {
    if (onUserSelect) {
      onUserSelect(val);
    }
    handleClose();
  };

  const handleSettings = () => {
    if (onSettings && current) {
      onSettings(current);
    }
    handleClose();
  };

  const handleAdd = () => {
    handleClose();
    onAdd();
  };

  return (
    <div>
      <Button
        aria-controls="customized-menu"
        aria-haspopup="true"
        color="primary"
        onClick={handleMenuClick}
      >
        {current?.label || `${t('btn.Add_User')} `}
        {current ? <PersonIcon /> : <PersonAddIcon fontSize="small" />}
      </Button>
      <Menu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {!!list.length && (
          <div>
            {!!current && (
              <div>
                <MenuItem onClick={handleSettings}>
                  <ListItemIcon color="#ccc" className={'item-icon'}>
                    <SettingsIcon />
                  </ListItemIcon>
                  <ListItemText primary={current.label} />
                </MenuItem>
                <Divider />
              </div>
            )}
            {list.map((val) => {
              const handClick = () => {
                handleUserSelect(val);
              };
              const selected = current?.value === val.value;

              return (
                <MenuItem key={val.value} onClick={handClick}>
                  <ListItemText primary={val.label} className={cn({selected})} />
                </MenuItem>
              );
            })}
            <Divider />
          </div>
        )}
        <MenuItem onClick={handleAdd}>
          <ListItemIcon color="#ccc" className={'item-icon'}>
            <PersonAddIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary={'Add User'} />
        </MenuItem>
      </Menu>
    </div>
  );
};

export default SelectUser;
