import {LangType} from '~/types';

export interface ILocales {
  lang: LangType;
  data: Record<string, string>;
}

export const locales: ILocales[] = [
  {
    lang: LangType.en,
    data: {
      // Home: 'Home',
      Home: 'Main',
      Check_Vision: 'Check Vision',
      Statistics: 'Statistics',
      Info: 'Info',
      Contacts: 'Contacts',
    },
  },
  {
    lang: LangType.es,
    data: {
      // Home: 'Casa',
      // Home: 'Principal',
      Home: 'Base',
      Check_Vision: 'Verificar',
      // Check_Vision: 'Verificar la Visión',
      Statistics: 'Estadísticas',
      Info: 'Info',
      Contacts: 'Contactos',
    },
  },
  {
    lang: LangType.ua,
    data: {
      Home: 'Основна',
      Check_Vision: 'Перевірити Зір',
      Statistics: 'Статистика',
      Info: 'Інфо',
      Contacts: 'Контакти',
    },
  },
  {
    lang: LangType.ru,
    data: {
      Home: 'Основная',
      Check_Vision: 'Проверить Зрение',
      Statistics: 'Статистика',
      Info: 'Инфо',
      Contacts: 'Контакт',
    },
  },
];
