/**
 * @fileOverview Routers
 */

// import {ConnectedRouter} from 'connected-react-router';
// import {History} from 'history';
import React from 'react';
import {
  Navigate, Route, Routes,
  // RouteProps,
  BrowserRouter
} from 'react-router-dom';

import i18n from '~/services/i18n';
import {
  CheckPage,
  HomePage,
  UserProfileAddPage,
  UserProfileEditPage,
  SettingsPage,
  StatisticsPage,
  InfoPage,
  Contacts,
} from './components/pages';
import {Drawer} from './components/blocks';

import {ROUTES} from './constants';
import {LangType} from '~/types';

/* interface IOwnProps extends RouteProps {
  history: History;
}*/

// type IRouterProps = IOwnProps;

const AppRouter = (): JSX.Element => {
// const AppRouter = ({history}: IRouterProps): JSX.Element => {
  const lang = i18n.language || LangType.en;
  const baseURL = lang === LangType.en ? '' : lang;

  return (
    // <ConnectedRouter history={history}>
    <BrowserRouter basename={baseURL}>
      <Drawer>
        <Routes>
          <Route path={ROUTES.CHECK} element={<CheckPage />} />
          <Route path={ROUTES.HOME} element={<HomePage/>} />
          <Route path={`${ROUTES.PROFILE}/:id/edit`} element={<UserProfileEditPage/>} />
          <Route path={ROUTES.SETTINGS} element={<SettingsPage/>} />
          <Route path={ROUTES.STATISTIC} element={<StatisticsPage/>} />
          <Route path={ROUTES.INFO} element={<InfoPage />} />
          <Route path={ROUTES.CONTACTS} element={<Contacts/>} />
          <Route path="*" element={<Navigate to={ROUTES.HOME} replace/>} />
        </Routes>
      </Drawer>
    </BrowserRouter>
    // </ConnectedRouter>
  );
};

export default AppRouter;
