import cn from 'classnames';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import {IStatistic} from '~/types';
import {getStatisticCorrectIdx} from '~/services/utils';
import {LabelValue} from '~/components/ui';
import './statisticsTable.scss';
import {useTranslation} from 'react-i18next';

interface IStatisticsTable {
  left: IStatistic[];
  right: IStatistic[];
  percent?: number;
  minCorrectAnswers?: number;
}

export const StatisticsTable = ({
  left = [],
  right = [],
  percent,
  minCorrectAnswers,
}: IStatisticsTable) => {
  const {t} = useTranslation();

  const renderSection = (title: string, arr: IStatistic[]) => {
    const {row, error} = getStatisticCorrectIdx(arr, percent, minCorrectAnswers);
    return (
      <div className="statistics-table-section">
        <Typography variant="body1">{title}</Typography>
        {arr.length ? (
          arr.map((val, idx) => (
            <div className={cn('row', {error: !!error && idx === row})} key={val.vCoef}>
              <LabelValue label={'V Coeff'} value={val.vCoef} className="label-value-wrapper" />
              <LabelValue label={'Correct'} value={val.corrects} className="label-value-wrapper" />
              <LabelValue label={'Error'} value={val.errors} className="label-value-wrapper" />
            </div>
          ))
        ) : (
          <Typography variant="body1">{t('common.No_Data')}</Typography>
        )}
      </div>
    );
  };

  return (
    <div className="statistics-table-wrapper">
      {renderSection(t('common.Left_Eye'), left)}
      <Divider variant="fullWidth" orientation="vertical" />
      {renderSection(t('common.Right_Eye'), right)}
    </div>
  );
};
