import cn from 'classnames';
import React from 'react';
import Typography from '@material-ui/core/Typography';

import './labelValue.scss';

interface ILabelValue {
  label: string;
  value: string | number;
  className?: string;
}

const LabelValue = ({label, value, className}: ILabelValue): JSX.Element => (
  <div className={cn('label-value-wrapper', className)}>
    <div className="label-wrapper">
      <Typography variant="body2">{`${label}:`}</Typography>
    </div>
    <div className="value-wrapper">
      <Typography variant="body2">{value.toString()}</Typography>
    </div>
  </div>
);

export default LabelValue;
