import {ImageType} from '~/types';

export interface IStatistic {
  vCoef: number;
  corrects: number;
  errors: number;
}

export interface IOneDateStatistics {
  date: string;
  imageType?: ImageType;
  left: IStatistic[];
  right: IStatistic[];
}

export interface IUserStatistics {
  userId: string;
  data: IOneDateStatistics[];
}

export interface IUsersStatistics {
  usersData: IUserStatistics[];
}

export const DEFAULT_STATISTICS = {usersData: []};
