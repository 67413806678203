import React from 'react';
import {withFormik, FormikProps} from 'formik';
import * as yup from 'yup';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import {useTranslation} from 'react-i18next';

import {DEFAULT_SETTINGS, ISettings} from '~/types';
import {
  MIN_DISTANCE,
  MAX_DISTANCE,
  MIN_CORRECT_PERCENT,
  MAX_CORRECT_PERCENT,
} from '~/constants/data';
import './settings.scss';
import {confirm} from '~/components/modals';

const validationSchema = yup.object().shape({
  distance: yup.number().required('Required field').min(MIN_DISTANCE).max(MAX_DISTANCE),
  correctPercent: yup
    .number()
    .required('Required field')
    .min(MIN_CORRECT_PERCENT)
    .max(MAX_CORRECT_PERCENT),
});

export interface OwnProps {
  title: string;
  settings: Maybe<ISettings>;
  onSubmit: (val: ISettings) => void;
  onCancel: () => void;
  resetStorage: () => void;
}

type ISettingsProfileForm = OwnProps & FormikProps<ISettings>;

const SettingsForm = ({
  onCancel,
  resetStorage,
  title,
  values,
  errors = {},
  handleSubmit,
  handleChange,
  handleReset,
}: ISettingsProfileForm): JSX.Element => {
  const {t} = useTranslation();

  const handleResetStorage = () => {
    confirm({
      onSubmit: resetStorage,
      message: t('Settings.All_data_in_local_storage'),
    });
  };

  return (
    <div className="form-wrapper">
      <Typography variant="h5">{title}</Typography>
      <form onSubmit={handleSubmit} className="form">
        <TextField
          type="numeric"
          label={`${t('common.Distance')}, m`}
          name={'distance'}
          value={values?.distance}
          onChange={handleChange}
          error={!!errors?.distance}
          helperText={errors?.distance}
          className="form-field"
        />
        <TextField
          type="numeric"
          label={`${t('common.Allowable_Percentage')}, %`}
          name={'correctPercent'}
          value={values.correctPercent}
          onChange={handleChange}
          error={!!errors.correctPercent}
          helperText={errors.correctPercent}
          className="form-field"
        />
        <div className={'form-btn-wrapper'}>
          <Button type="submit" color="primary" className={'form-btn'} variant="contained">
            {t('btn.Submit')}
          </Button>
          <Button type="button" className={'form-btn'} onClick={handleReset}>
            {t('btn.Reset')}
          </Button>
          <Button type="button" className={'form-btn'} onClick={onCancel}>
            {t('btn.Cancel')}
          </Button>
        </div>
      </form>
      <Divider className={'under-form-section'} />
      <div className={'under-form-section'}>
        <Button type="submit" variant="contained" onClick={handleResetStorage}>
          {t('Settings.Reset_Local_Storage')}
        </Button>
      </div>
    </div>
  );
};

export default withFormik<OwnProps, ISettings>({
  mapPropsToValues(props) {
    return props.settings || DEFAULT_SETTINGS;
  },
  validationSchema,
  validateOnChange: false,
  enableReinitialize: true,
  async handleSubmit(values, {props}) {
    props.onSubmit(values);
  },
})(SettingsForm);
