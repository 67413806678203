import React from 'react';
import Typography from '@material-ui/core/Typography';
import {Line} from 'react-chartjs-2';

import './statistics.scss';
import {useTranslation} from 'react-i18next';

export interface OwnProps {
  title: string;
  leftData: number[];
  rightData: number[];
  xLabels: string[];
}

const StatisticsView = ({title, leftData, rightData, xLabels = []}: OwnProps): JSX.Element => {
  const {t} = useTranslation();

  const left = {
    labels: xLabels,
    datasets: [
      {
        label: t('common.Left_Eye'),
        data: leftData,
        fill: true,
        borderColor: 'rgb(75, 192, 192)',
        tension: 0.1,
      },
    ],
  };
  const right = {
    labels: xLabels,
    datasets: [
      {
        label: t('common.Right_Eye'),
        data: rightData,
        fill: false,
        borderColor: 'rgb(10, 102, 192)',
        tension: 0.1,
      },
    ],
  };
  return (
    <div className="statistics-wrapper">
      <Typography variant="h5">{title}</Typography>
      <div className="chart-section">
        <div className={'chart-wrapper'}>
          <Line
            id="left-chart"
            className={'chart-wrapper'}
            data={left}
            type="line"
            // options={
            //   {
            //     // responsive: true,
            //     // maintainAspectRatio: true
            //   }
            // }
          />
        </div>
      </div>
      <div className="chart-section">
        <div className={'chart-wrapper'}>
          <Line
            id="right-chart"
            className={'chart-wrapper'}
            data={right}
            type="line"
            // options={
            //   {
            //     // responsive: true,
            //     // maintainAspectRatio: true
            //   }
            // }
          />
        </div>
      </div>
    </div>
  );
};

export default StatisticsView;
