import React from 'react';
import {Link} from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import {ROUTES} from '~/constants';
import {useTranslation} from 'react-i18next';
import './home.scss';

const Home = () => {
  const {t} = useTranslation();

  return (
    <div className="home-wrapper">
      <div className="home-wrapper-image" />
      <Typography variant="h5">{t('Home.Check_Vision')}</Typography>
      <Typography variant="h6">{t('Home.Check_vision_on_a_computer')}.</Typography>
      <Divider className="divider" />
      <div className="home-imgs-wrapper">
        <div className="page-img-1" />
        <div className="page-img-c" />
      </div>

      <Divider className="divider" />
      <Typography variant="h6">{t('Home.Steps_for_test_vision')}</Typography>
      <Typography variant="body1">1. {t('Home.Set_settings_and_user')}</Typography>
      <Typography variant="body1">2. {t('Home.Vision_Testing')}</Typography>
      <Typography variant="body1">3. {t('Home.Watch_statistics')}</Typography>
      <Link to={ROUTES.INFO} className="home-link-wrapper">
        {t('Home.More_Info')}
      </Link>
      <Divider className="divider" />

      <Link to={ROUTES.CHECK} className="home-start-btn-wrapper">
        <Typography variant="h4" className="home-start-btn">
          {t('Home.START')}
        </Typography>
      </Link>
    </div>
  );
};

export default Home;
