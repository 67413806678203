// import {connectRouter, routerMiddleware, RouterState} from 'connected-react-router';
// import {createBrowserHistory, History} from 'history';
import {applyMiddleware, combineReducers, createStore} from 'redux';
import {composeWithDevTools} from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import {BASE_URL} from '~/constants/config';

import {initApiServices} from '~/services/api';
import common, {CommonStateT} from './common';
import users, {UsersStateT, sagas as usersSagas} from './users';
import settings, {SettingsStateT, sagas as settingsSagas} from './settings';
import statistics, {StatisticsStateT, sagas as statisticsSagas} from './statistics';

export interface IReduxStore {
  // router: RouterState;
  common: CommonStateT;
  users: UsersStateT;
  settings: SettingsStateT;
  statistics: StatisticsStateT;
}

export const api = initApiServices(BASE_URL);

// const history = createBrowserHistory() as History;
const reducers = combineReducers<IReduxStore>({
  // router: connectRouter(history),
  common,
  users,
  settings,
  statistics,
});
const sagaMiddleware = createSagaMiddleware();
const store = createStore(
  reducers,
  // composeWithDevTools(applyMiddleware(routerMiddleware(history), sagaMiddleware)),
  composeWithDevTools(applyMiddleware(sagaMiddleware)),
);

const sagas = [
  usersSagas,
  settingsSagas,
  statisticsSagas,
];
sagas.forEach((saga) => sagaMiddleware.run(saga));

export {
  store
//  history
};