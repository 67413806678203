import React from 'react';
import {useSelector} from 'react-redux';

import {IOneDateStatistics, IUser, IUserStatistics} from '~/types';
import {IReduxStore} from '~/store';
import {getStatisticRate} from '~/services/utils';
import {Warning} from '~/components/blocks';
import StatisticsView from './StatisticsView';
import {useTranslation} from 'react-i18next';

const Statistics = () => {
  const {t} = useTranslation();

  const statistics: IUserStatistics[] = useSelector((state: IReduxStore) => state.statistics.data);
  const current: Maybe<IUser> = useSelector((state: IReduxStore) => state.users.current);
  const correctPercent: number = useSelector(
    (state: IReduxStore) => state.settings.data?.correctPercent,
  );

  const isOk = !!current && !!statistics.length;
  let leftData: number[] = [];
  let rightData: number[] = [];
  let xLabels: string[] = [];
  if (isOk) {
    const currentStatistics: Maybe<IUserStatistics> = statistics.find(
      (val) => val.userId === current?.id,
    );
    const data: Maybe<IOneDateStatistics[]> = currentStatistics?.data;
    if (data?.length) {
      const isOnlyDate = data.length > 4;
      xLabels = data.map((val) => (isOnlyDate ? val.date?.split(',')[0] : val.date));
      leftData = data.map((val) => getStatisticRate(val.left, correctPercent));
      rightData = data.map((val) => getStatisticRate(val.right, correctPercent));
    }
  }

  return isOk ? (
    <StatisticsView
      title={t('common.Statistics')}
      leftData={leftData}
      rightData={rightData}
      xLabels={xLabels}
    />
  ) : (
    <Warning
      title={t('Statistics.Empty_statistics')}
      message={t('Statistics.Please_create_user_and_pass')}
    />
  );
};

export default Statistics;
