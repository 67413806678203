import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import DrawerMUI from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import SvgIcon from '@material-ui/core/SvgIcon';
import Tooltip from '@material-ui/core/Tooltip';
import cn from 'classnames';
import React, {FC, SVGProps, useEffect, useState} from 'react';
import {Link, useLocation} from 'react-router-dom';
import {
  useDispatch,
  // useSelector
} from 'react-redux';

import {
  BarChartIcon,
  VisibilityIcon,
  HomeIcon,
  ArrowRightIcon,
  InfoIcon,
  MailIcon,
} from '~/assets/icons';
// import {IReduxStore} from '~/store';
import {ROUTES} from '~/constants';
import {AppBar} from '~/components/blocks';
import {getUsersAction} from '~/store/users';
import {getSettingsAction} from '~/store/settings';
import {getStatisticsAction} from '~/store/statistics';
// import Mail from '@material-ui/icons/Mail';
import i18n, {getLocales} from '~/services/i18n';
import {LangType} from '~/types';
import './drawer.scss';

interface IDrawerItem {
  item?: {
    label: string;
    link: string;
    Icon: typeof SvgIcon | FC<SVGProps<SVGSVGElement>>;
  };
  divider?: boolean;
}

interface IOwnProps {
  children: JSX.Element;
}

type DrawerWrapperProps = IOwnProps;

const Drawer = ({children}: DrawerWrapperProps): JSX.Element => {
  const dispatch = useDispatch();
  const currentLang = i18n.language as LangType;

  const ITEMS: IDrawerItem[] = [
    {
      item: {
        label: getLocales(currentLang, 'Home'),
        // label: 'Home',
        link: ROUTES.HOME,
        Icon: HomeIcon,
      },
    },
    {
      item: {
        label: getLocales(currentLang, 'Check_Vision'),
        // label: 'Check Vision',
        link: ROUTES.CHECK,
        Icon: VisibilityIcon,
      },
    },
    {
      item: {
        label: getLocales(currentLang, 'Statistics'),
        // label: 'Statistics',
        link: ROUTES.STATISTIC,
        Icon: BarChartIcon,
      },
    },
    {
      item: {
        label: getLocales(currentLang, 'Info'),
        // label: 'Info',
        link: ROUTES.INFO,
        Icon: InfoIcon,
      },
    },
    {
      item: {
        label: getLocales(currentLang, 'Contacts'),
        // label: 'Contacts',
        link: ROUTES.CONTACTS,
        Icon: MailIcon,
      },
    },
  ];

  // const pathname = useSelector((state: IReduxStore) => state.router.location?.pathname || '');
  const {pathname} = useLocation();

  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    dispatch(getUsersAction());
    dispatch(getSettingsAction());
    dispatch(getStatisticsAction());
  }, []);
  const toggleOpenMenu = (): void => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={'drawer-wrapper'}>
      <CssBaseline />
      <AppBar
        isDrawerOpen={isOpen}
        toggleDrawerOpen={toggleOpenMenu}
        className={cn('app-bar', {'drawer-app-bar-shift': isOpen})}
      />
      <DrawerMUI
        variant="permanent"
        className={cn('drawer', {'drawer-open': isOpen})}
        classes={{
          paper: cn({
            'drawer-open': isOpen,
            'drawer-close': !isOpen,
          }),
        }}
        open={isOpen}
      >
        <div className={'drawer-toolbar'}>
          <IconButton onClick={toggleOpenMenu}>
            {!isOpen ? <ArrowRightIcon /> : <ArrowRightIcon className="rotate180" />}
          </IconButton>
        </div>
        <Divider />
        <List className="drawer-list">
          {ITEMS.map((i: IDrawerItem, idx: number) => {
            if (i.divider) {
              return <Divider key={`divider${idx}`} />;
            }

            const {item} = i;

            if (!item) {
              return null;
            }

            const DrawerIcon = item.Icon;
            const selected = item.link === pathname;
            const iconClass = selected ? 'icon-tab-active' : 'icon-tab';

            return (
              <Link to={item.link} key={`${item.link}${item.label}`}>
                <Tooltip title={item.label} placement="right">
                  <ListItem button selected={selected} className="drawer-list-item">
                    <ListItemIcon className="drawer-list-item-icon">
                      <DrawerIcon className={iconClass} />
                    </ListItemIcon>
                    <ListItemText primary={item.label} className="drawer-item-label" />
                  </ListItem>
                </Tooltip>
              </Link>
            );
          })}
        </List>
      </DrawerMUI>
      <div className={'drawer-content'}>{children}</div>
    </div>
  );
};

export default Drawer;
