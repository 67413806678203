import cn from 'classnames';
import React, {FC, SVGProps} from 'react';

import './buttons.scss';

interface IFilterArrows {
  Image: FC<SVGProps<SVGSVGElement>>;
  onClick: () => void;
  className?: string;
  width?: number;
  fill?: string;
  isOutlined?: boolean;
  disabled?: boolean;
}

const SvgButton = ({
  Image,
  onClick,
  className,
  width = 24,
  fill = 'black',
  disabled,
}: IFilterArrows): JSX.Element => {
  const handleClick = () => {
    if (!disabled) {
      onClick();
    }
  };
  return (
    <div
      className={cn('svg-btn-wrapper', {'svb-button-disabled': disabled}, className)}
      style={{
        width,
        height: width,
        borderRadius: width / 2,
      }}
    >
      <Image fill={fill} width={width} height={width} onClick={handleClick} />
    </div>
  );
};

export default SvgButton;
