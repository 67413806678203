import React from 'react';
import {confirmAlert} from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Button from '@material-ui/core/Button';

import './confirm.scss';

interface IConfirm {
  title?: string;
  message?: string;
  submitText?: string;
  cancelText?: string;
  onSubmit?: () => void;
  render?: () => JSX.Element;
}

const confirm = ({
  title = 'Are you sure?',
  message = 'Confirm to do this',
  onSubmit,
  submitText = 'Yes',
  cancelText = 'No',
  render
}: IConfirm) => {
  confirmAlert({
    // eslint-disable-next-line react/prop-types,react/display-name
    customUI: ({onClose}) => {
      const handleClick = () => {
        if (onSubmit) {
          onSubmit();
        }
        onClose();
      };
      return (
        <div className="confirm-wrapper">
          <h1>{title}</h1>
          <p>{message}</p>
          <div className="confirm-render-wrapper">{!!render && render()}</div>
          {onSubmit && (
            <Button type="button" color="primary" variant="contained" onClick={handleClick}>
              {submitText}
            </Button>
          )}
          <Button type="button" onClick={onClose}>
            {cancelText}
          </Button>
        </div>
      );
    },
  });
};

export default confirm;
