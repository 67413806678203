import BaseApi from './BaseApi';

export default class ExampleApi {
  constructor(baseApi: BaseApi) {
    this.baseApi = baseApi;
  }

  public baseApi: BaseApi;

  // public getCountries = (): Promise<any> => this.baseApi.get('all');
}

