import React, {FC, SVGProps} from 'react';
import Typography from '@material-ui/core/Typography';
import {useNavigate} from 'react-router-dom';

import {ROUTES} from '~/constants';
import './warning.scss';
import Button from '@material-ui/core/Button';

interface IWarning {
  title: string;
  message?: string;
  Icon?: FC<SVGProps<SVGSVGElement>>;
  onCancel?: () => void;
}

export const Warning = ({title = 'Empty data', message, onCancel}: IWarning) => {
  const navigate = useNavigate();

  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    } else {
      navigate(ROUTES.HOME);
    }
  };

  return (
    <div className="warning-wrapper">
      <Typography variant="h5">{title}</Typography>
      <Typography variant="h6">{message}</Typography>
      <div className="warning-btn">
        <Button type="button" className={'form-btn'} onClick={handleCancel} variant="contained">
          Cancel
        </Button>
      </div>
    </div>
  );
};
