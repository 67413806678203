import React from 'react';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import {useTranslation} from 'react-i18next';

import './contacts.scss';

const Contacts = () => {
  const {t} = useTranslation();

  return (
    <div className="contacts-wrapper">
      <Typography variant="h5">{t('Contacts.Contacts')}</Typography>
      <Typography variant="body1">{t('Contacts.If_you_want_to_add_some_functionality')}</Typography>
      <Typography variant="body1">{t('Contacts.Please_write')}</Typography>
      <div className="row">
        <Typography variant="body1" color="primary">
          info
        </Typography>
        <Typography variant="h6" color="primary">
          @
        </Typography>
        <Typography variant="body1" color="primary">
          check
        </Typography>
        <Typography variant="body1" color="primary">
          .
        </Typography>
        <Typography variant="body1" color="primary">
          vision
        </Typography>
      </div>
      <Divider className="divider" />
    </div>
  );
};

export default Contacts;
