import {IUser} from '~/types';
import {baseStorage} from '.';

const USERS_STORAGE_KEY = 'checkVision:users';

export const getUsers = async(): Promise<Maybe<IUser[]>> => {
  const res: string = await baseStorage.get(USERS_STORAGE_KEY);
  if (res) {
    const data: IUser[] = JSON.parse(res);
    return data;
  }
  return null;
};

export async function setUsers(value: IUser[]) {
  const data = JSON.stringify(value);
  await baseStorage.set(USERS_STORAGE_KEY, data);
}

export const addUserValue = async(userName: string, value: IUser) => {
  const res: Maybe<IUser[]> = await getUsers();
  if (res) {
    const idx = res.findIndex((val) => val.name === userName);
    const newData =
      idx !== -1
        ? [
          ...res.slice(0, idx),
          {
            ...res[idx],
            ...value,
          },
          ...res.slice(idx + 1),
        ]
        : [...res, value];
    await setUsers(newData);
  }
};

export const removeUser = async(userName: string) => {
  const res: Maybe<IUser[]> = await getUsers();
  if (res) {
    const idx = res.findIndex((val) => val.name === userName);
    if (idx !== -1) {
      const newData = [...res.slice(0, idx), ...res.slice(idx + 1)];
      await setUsers(newData);
    }
  }
};

export const resetUsers = async() => {
  await baseStorage.reset(USERS_STORAGE_KEY);
};

export default {
  getUsers,
  setUsers,
  addUserValue,
  removeUser,
  resetUsers,
};
