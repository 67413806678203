import React from 'react';
import {withFormik, FormikProps} from 'formik';
import * as yup from 'yup';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import {useTranslation} from 'react-i18next';

import {DEFAULT_NEW_USER, IUser, INewUser, ILabelValue, ImageType} from '~/types';
import {confirm} from '~/components/modals';
import './userProfile.scss';

const validationSchema = yup.object().shape({
  name: yup.string().required('Required field'),
  defaultVCoef: yup.number().required('Required field').min(0.2).max(3),
  imageType: yup.string().required('Required field'),
});

export interface OwnProps {
  title: string;
  user?: Maybe<IUser>;
  onSubmit: (val: INewUser) => void;
  onCancel: () => void;
  onRemove?: () => void;
}

type IUserProfileForm = OwnProps & FormikProps<INewUser>;

const UserProfileForm = ({
  title,
  onCancel,
  onRemove,
  values,
  errors,
  handleSubmit,
  handleChange,
  handleReset,
}: IUserProfileForm): JSX.Element => {
  const {t} = useTranslation();

  const IMAGE_TYPE_OPTIONS: ILabelValue<ImageType>[] = [
    {
      value: ImageType.Images,
      label: t('common.Images'),
    },
    {
      value: ImageType.CType,
      label: `C ${t('common.Type')}`,
    },
    {
      value: ImageType.EType,
      label: `E ${t('common.Type')}`,
    },
  ];

  const handleRemove = () => {
    if (onRemove) {
      confirm({
        onSubmit: onRemove,
        message: `Confirm to remove user ${values.name}`,
      });
    }
  };

  return (
    <div className="form-wrapper">
      <Typography variant="h5">{title}</Typography>
      <form onSubmit={handleSubmit} className="form">
        <TextField
          label={t('common.Name')}
          name={'name'}
          value={values.name}
          onChange={handleChange}
          error={!!errors.name}
          helperText={errors.name}
          className="form-field"
        />
        <TextField
          type="numeric"
          label={t('common.Default_Visual_Coefficient')}
          name={'defaultVCoef'}
          value={values.defaultVCoef}
          onChange={handleChange}
          error={!!errors.defaultVCoef}
          helperText={errors.defaultVCoef}
          className="form-field"
        />
        <FormControl className="form-field">
          <InputLabel>{t('common.Image_Type')}</InputLabel>
          <Select
            name={'imageType'}
            value={values.imageType}
            onChange={handleChange}
            className="form-field"
            error={!!errors.imageType}
          >
            {IMAGE_TYPE_OPTIONS.map((val) => (
              <MenuItem key={val.value} value={val.value}>
                {val.label}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>{errors.imageType}</FormHelperText>
        </FormControl>
        <div className={'form-btn-wrapper'}>
          <Button type="submit" color="primary" className={'form-btn'} variant="contained">
            {t('btn.Submit')}
          </Button>
          <Button type="button" className={'form-btn'} onClick={handleReset}>
            {t('btn.Reset')}
          </Button>
          <Button type="button" className={'form-btn'} onClick={onCancel}>
            {t('btn.Cancel')}
          </Button>
          <Divider className="divider" />
          <Button type="button" className={'form-btn'} onClick={handleRemove} variant="contained">
            {t('btn.Remove')}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default withFormik<OwnProps, INewUser>({
  mapPropsToValues(props) {
    return props.user || DEFAULT_NEW_USER;
  },
  validationSchema,
  validateOnChange: false,
  enableReinitialize: true,
  async handleSubmit(values, {props}) {
    props.onSubmit(values);
  },
})(UserProfileForm);
