import {DEFAULT_DISTANCE, DEFAULT_CORRECT_PERCENT} from '~/constants/data';

export interface ISettings {
  distance: number;
  correctPercent: number;
}

export const DEFAULT_SETTINGS: ISettings = {
  distance: DEFAULT_DISTANCE,
  correctPercent: DEFAULT_CORRECT_PERCENT,
};
