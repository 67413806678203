import debounce from 'lodash/debounce';
import sortBy from 'lodash/sortBy';
import React, {useState, FC} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';

import {
  ArrowRightIcon,
  CloseIcon,
  DoneAllIcon,
  DoneIcon,
  EyeLeftIcon,
  EyeRightIcon,
  MinusIcon,
  PlusIcon,
  ReplayIcon,
  ReturnLeftIcon,
} from '~/assets/icons';
import {
  DEFAULT_DISTANCE,
  DEFAULT_IMAGE,
  DEFAULT_IMAGE_TYPE,
  DEFAULT_V_COEF,
  DEFAULT_V_DELTA,
  IMAGES,
  MAX_V_COEF,
  MIN_CORRECT_ANSWERS,
  MIN_V_COEF,
  ROUTES,
} from '~/constants';
import {checkStatisticForEnd, getImageHeight} from '~/services/utils';
import {IReduxStore} from '~/store';
import {addUserStatisticsAction} from '~/store/statistics';
import {
  EyeType,
  ICheckSideBtn,
  ImageType,
  IOneDateStatistics,
  IStatistic,
  IUserStatistics,
  SvgName,
} from '~/types';
import CheckView from './CheckView';
import {confirm} from '~/components/modals';
import {StatisticsTable} from '~/components/blocks';
import {useTranslation} from 'react-i18next';

const Check: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {t} = useTranslation();

  const distance: number = useSelector(
    (state: IReduxStore) => state.settings.data?.distance || DEFAULT_DISTANCE,
  );
  const correctPercent: number = useSelector(
    (state: IReduxStore) => state.settings.data?.correctPercent,
  );
  const statisticsData: IUserStatistics[] = useSelector(
    (state: IReduxStore) => state.statistics.data,
  );
  const defaultVCoef: number =
    useSelector((state: IReduxStore) => state.users.current?.defaultVCoef || DEFAULT_V_COEF);
  const currentUserId: Maybe<string> = useSelector((state: IReduxStore) => state.users.current?.id);
  const imageType: ImageType =
    useSelector((state: IReduxStore) => state.users.current?.imageType || DEFAULT_IMAGE_TYPE);

  const [eye, setEye] = useState<EyeType>(EyeType.LEFT);
  const [image, setImage] = useState<SvgName>(DEFAULT_IMAGE);
  const [restImages, setRestImages] = useState<SvgName[]>(IMAGES);
  const [vCoef, setVCoef] = useState<number>(defaultVCoef);
  const [leftEyeStatistics, setLeftEyeStatistics] = useState<IStatistic[]>([]);
  const [rightEyeStatistics, setRightEyeStatistics] = useState<IStatistic[]>([]);
  const [rotate, setRotate] = useState<number>(0);
  const isImageType = imageType === ImageType.Images;

  const onLeftEyeClick = debounce(() => {
    setEye(EyeType.LEFT);
    setRestImages(IMAGES);
  }, 200);

  const onRightEyeClick = debounce(() => {
    setEye(EyeType.RIGHT);
    setRestImages(IMAGES);
  }, 200);

  const getNewRotate = () => {
    const rotateNum = Math.ceil(Math.random() * 4);
    let i = 3;
    while (i > 0) {
      const newRotate = rotateNum * 90;
      if (newRotate !== rotate) {
        return newRotate;
      }
      i--;
    }
    return 0;
  };

  const setNewRotate = () => {
    const newRotate = getNewRotate();
    setRotate(newRotate);
  };

  const onUpClick = debounce(() => {
    const newVCoef: number = Math.round((vCoef - DEFAULT_V_DELTA) * 100) / 100;
    if (newVCoef >= MIN_V_COEF) {
      setVCoef(newVCoef);
    }
    if (isImageType) {
      setRestImages(IMAGES);
    } else {
      // setNewRotate();
    }
  }, 200);

  const onDownClick = debounce(() => {
    const newVCoef = Math.round((vCoef + DEFAULT_V_DELTA) * 100) / 100;
    if (newVCoef <= MAX_V_COEF) {
      setVCoef(newVCoef);
    }
    if (isImageType) {
      setRestImages(IMAGES);
    } else {
      // setNewRotate();
    }
  }, 200);

  const onDoneClick = debounce((isCorrect: boolean) => {
    const idx = restImages.findIndex((val) => val.name === image.name);
    const newRestImages = [...restImages.slice(0, idx), ...restImages.slice(idx + 1)];
    const len: number = newRestImages.length;

    const setStat = eye === EyeType.LEFT ? setLeftEyeStatistics : setRightEyeStatistics;
    const stat = eye === EyeType.LEFT ? leftEyeStatistics : rightEyeStatistics;
    const statIdx = stat.findIndex((val) => val.vCoef === vCoef);
    if (statIdx !== -1) {
      const corrects = stat[statIdx]?.corrects || 0;
      const errors = stat[statIdx]?.errors || 0;
      const coefStat: IStatistic = {
        ...stat[statIdx],
        corrects: isCorrect ? corrects + 1 : corrects,
        errors: isCorrect ? errors : errors + 1,
      };
      const newStat = sortBy(
        [...stat.slice(0, statIdx), coefStat, ...stat.slice(statIdx + 1)],
        (val) => -val.vCoef,
      );
      setStat(newStat);
    } else {
      const newStat = sortBy(
        [
          ...stat,
          {
            vCoef,
            corrects: isCorrect ? 1 : 0,
            errors: isCorrect ? 0 : 1,
          },
        ],
        (val) => -val.vCoef,
      );
      setStat(newStat);
    }

    if (isImageType) {
      setRestImages(newRestImages);
      if (len > 0) {
        const newIdx = Math.floor(Math.random() * len);
        setImage(newRestImages[newIdx]);
      }
    } else {
      setNewRotate();
    }
  }, 200);

  const onReturnClick = debounce(() => {
    setRestImages(IMAGES);
  }, 200);

  const clearAndReplay = () => {
    setRestImages(IMAGES);
    setLeftEyeStatistics([]);
    setRightEyeStatistics([]);
  };

  const onClearAndReplayClick = debounce(() => {
    confirm({
      onSubmit: clearAndReplay,
      message: t('Check.Confirm_to_remove_statistics'),
    });
  }, 200);

  const onNextClick = debounce(() => {
    if (isImageType) {
      const len: number = restImages.length;
      const idx = Math.floor(Math.random() * len);
      setImage(restImages[idx]);
    } else {
      setNewRotate();
    }
  }, 200);

  const finish = () => {
    if (currentUserId) {
      const newDate = new Date();
      const date = newDate.toLocaleString(); // "22/05/2021, 21:17:20"
      const oneDateStatistics: IOneDateStatistics = {
        date,
        imageType,
        left: leftEyeStatistics,
        right: rightEyeStatistics,
      };
      const idx = statisticsData.findIndex((val) => val.userId === currentUserId);
      const userData: IOneDateStatistics[] = idx !== -1 ? statisticsData[idx].data : [];
      userData.push(oneDateStatistics);
      const userStatistics: IUserStatistics = {
        userId: currentUserId,
        data: userData,
      };
      dispatch(addUserStatisticsAction(userStatistics));
      navigate(ROUTES.STATISTIC);
    }
  };

  const onEnd = debounce(function() {
    const isLeftOk = checkStatisticForEnd(leftEyeStatistics, correctPercent);
    const isRightOk = checkStatisticForEnd(rightEyeStatistics, correctPercent);
    const isBothOk = isLeftOk && isRightOk;
    const isBothFalse = !isLeftOk && !isRightOk;

    if (isBothOk) {
      const message = t('Check.Confirm_to_end_check');
      confirm({
        onSubmit: finish,
        message,
      });
    } else {
      const title = `${t('Check.Check_additionally')} ${!isLeftOk ? t('common.Left_Eye') : ''} ${isBothFalse ? t('common.and') : ''} ${!isRightOk && t('common.Right_Eye')}`;
      const message = `${t('Check.It_needs_to_receive_min')} ${correctPercent}% ${t('Check.correct_answers_and_not_less')} ${MIN_CORRECT_ANSWERS} ${t('Check.for_your_best_result')}`;
      // const cancelText = t('btn.Cancel');
      confirm({
        cancelText: t('btn.Cancel'),
        title,
        message,
        // eslint-disable-next-line react/display-name
        render: () => <StatisticsTable left={leftEyeStatistics} right={rightEyeStatistics} />,
      });
    }
  }, 200);

  const isImageLast = isImageType && restImages.length < 2;

  const eyesBtns: ICheckSideBtn[] = [
    {
      name: t('common.Left_Eye'),
      Svg: EyeLeftIcon,
      onClick: onLeftEyeClick,
      isActive: eye === EyeType.LEFT,
    },
    {
      name: t('common.Right_Eye'),
      Svg: EyeRightIcon,
      onClick: onRightEyeClick,
      isActive: eye === EyeType.RIGHT,
    },
  ];
  const manageBtns: ICheckSideBtn[] = [
    {
      name: 'Up',
      Svg: PlusIcon,
      // Svg: ArrowRightIcon,
      onClick: onUpClick,
      // className: 'rotate270',
      isDisabled: vCoef <= MIN_V_COEF,
    },
    {
      name: 'Down',
      Svg: MinusIcon,
      // Svg: ArrowRightIcon,
      onClick: onDownClick,
      // className: 'rotate90',
      isDisabled: vCoef >= MAX_V_COEF,
    },
    {
      name: 'Done',
      Svg: DoneIcon,
      onClick: () => onDoneClick(true),
      isDisabled: isImageLast,
    },
    {
      name: 'Error',
      Svg: CloseIcon,
      onClick: () => onDoneClick(false),
      isDisabled: isImageLast,
    },
    {
      name: 'Next',
      Svg: ArrowRightIcon,
      onClick: onNextClick,
      isDisabled: isImageLast,
    },
  ];
  if (isImageType) {
    manageBtns.push({
      name: 'Return',
      Svg: ReturnLeftIcon,
      onClick: onReturnClick,
      isActive: isImageLast,
    });
  }

  const sideBtns: ICheckSideBtn[][] = [
    eyesBtns,
    manageBtns,
    [
      {
        name: 'Clear and Replay',
        Svg: ReplayIcon,
        onClick: onClearAndReplayClick,
      },
    ],
    [
      {
        name: 'Done All Icon',
        Svg: DoneAllIcon,
        onClick: onEnd,
      },
    ],
  ];

  const imageSize = getImageHeight(distance, vCoef);

  return (
    <CheckView
      imageType={imageType}
      imageSize={imageSize}
      vCoef={vCoef}
      image={image}
      isImageLast={isImageLast}
      sideBtns={sideBtns}
      onDoneClick={onDoneClick}
      onNextClick={onNextClick}
      rotate={rotate}
    />
  );
};

export default Check;
