import React, {FC, ComponentType} from 'react';
import {FullScreen, useFullScreenHandle} from 'react-full-screen';

import {SvgButton} from '~/components/ui';
import {FullScreenIcon, FullScreenExitIcon} from '~/assets/icons';
import './fullScreen.scss';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const withFullScreen = <P extends Record<string, unknown>>(
  Component: FC<P> | ComponentType<P>,
  // eslint-disable-next-line react/display-name
) => (props: P): JSX.Element => {
    const handle = useFullScreenHandle();

    const onFullScreenClick = () => {
      if (handle.active) {
        handle.exit();
      } else {
        handle.enter();
      }
    };

    const Image = handle.active ? FullScreenExitIcon : FullScreenIcon;

    return (
      <FullScreen handle={handle} className="full-screen-wrapper">
        <Component {...props} />
        <div className="full-screen-btn">
          <SvgButton Image={Image} fill="#4444ff" width={48} onClick={onFullScreenClick} />
        </div>
      </FullScreen>
    );
  };