import {Reducer} from 'redux';

import {IUser} from '~/types';
import {takeEveryDefault} from '~/store/common';
import {usersStorage} from '~/services/localStorage';

// Actions
export enum UsersActions {
  GET_USERS = 'users/GET_USERS',
  SET_USERS = 'users/SET_USERS',
  SET_CURRENT_USER = 'users/SET_CURRENT_USER',
  RESET = 'users/RESET',
}

export type UsersLoadableT =
  | typeof UsersActions.GET_USERS
  | typeof UsersActions.SET_USERS
  | typeof UsersActions.RESET;

export interface IGetUsersAction {
  type: typeof UsersActions.GET_USERS;
}

export interface ISetUsersAction {
  type: typeof UsersActions.SET_USERS;
  payload: IUser[];
}

interface ISetCurrentUserAction {
  type: typeof UsersActions.SET_CURRENT_USER;
  payload: IUser;
}

interface IResetUsersAction {
  type: typeof UsersActions.RESET;
}

type UsersActionsT = IGetUsersAction | ISetUsersAction | IResetUsersAction | ISetCurrentUserAction;

export const getUsersAction = (): IGetUsersAction => ({type: UsersActions.GET_USERS});

export const setUsersAction = (payload: IUser[]): ISetUsersAction => ({
  type: UsersActions.SET_USERS,
  payload,
});

export const setCurrentUserAction = (payload: IUser): ISetCurrentUserAction => ({
  type: UsersActions.SET_CURRENT_USER,
  payload,
});

export const resetUsersAction = (): IResetUsersAction => ({type: UsersActions.RESET});

// Sagas
export function* sagas(): Generator {
  yield takeEveryDefault<null, Maybe<IUser[]>>(
    UsersActions.GET_USERS,
    usersStorage.getUsers,
    setUsersAction,
  );
  yield takeEveryDefault<IUser[], void>(UsersActions.SET_USERS, usersStorage.setUsers);
  yield takeEveryDefault<null, void>(UsersActions.RESET, usersStorage.resetUsers);
}

// Reducer
export interface IUsersState {
  data: IUser[];
  current: Maybe<IUser>;
}

export type UsersStateT = Readonly<IUsersState>;

const initialState: IUsersState = {
  data: [],
  current: null,
};
const reducer: Reducer<UsersStateT> = (
  state: IUsersState = initialState,
  action: UsersActionsT,
) => {
  switch (action.type) {
    case UsersActions.SET_USERS:
      return {
        ...state,
        data: action.payload,
        current: state.current || action.payload[0] || null,
      };

    case UsersActions.SET_CURRENT_USER:
      return {
        ...state,
        current: action.payload,
      };

    case UsersActions.RESET:
      return initialState;

    default:
      return state;
  }
};

export default reducer;
