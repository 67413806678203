import {Reducer} from 'redux';

import {DEFAULT_SETTINGS, ISettings} from '~/types';
import {takeEveryDefault} from '~/store/common';
import {settingsStorage} from '~/services/localStorage';

// Actions
export enum SettingsActions {
  GET_SETTINGS = 'settings/GET_SETTINGS',
  SET_SETTINGS = 'settings/SET_SETTINGS',
  RESET = 'settings/RESET',
}

export type SettingsLoadableT =
  | typeof SettingsActions.GET_SETTINGS
  | typeof SettingsActions.SET_SETTINGS
  | typeof SettingsActions.RESET;

export interface IGetSettingsAction {
  type: typeof SettingsActions.GET_SETTINGS;
}

export interface ISetSettingsAction {
  type: typeof SettingsActions.SET_SETTINGS;
  payload: ISettings;
}

interface IResetSettingsAction {
  type: typeof SettingsActions.RESET;
}

type SettingsActionsT = IGetSettingsAction | ISetSettingsAction | IResetSettingsAction;

export const getSettingsAction = (): IGetSettingsAction => ({type: SettingsActions.GET_SETTINGS});

export const setSettingsAction = (payload: ISettings): ISetSettingsAction => ({
  type: SettingsActions.SET_SETTINGS,
  payload,
});

export const resetSettingsAction = (): IResetSettingsAction => ({type: SettingsActions.RESET});

// Sagas
export function* sagas(): Generator {
  yield takeEveryDefault<null, Maybe<ISettings>>(
    SettingsActions.GET_SETTINGS,
    settingsStorage.getSettings,
    setSettingsAction,
  );
  yield takeEveryDefault<ISettings, void>(
    SettingsActions.SET_SETTINGS,
    settingsStorage.setSettings,
  );
  yield takeEveryDefault<null, void>(SettingsActions.RESET, settingsStorage.resetSettings);
}

// Reducer
export interface ISettingsState {
  data: ISettings;
}

export type SettingsStateT = Readonly<ISettingsState>;

const initialState: ISettingsState = {data: DEFAULT_SETTINGS};

const reducer: Reducer<SettingsStateT> = (
  state: ISettingsState = initialState,
  action: SettingsActionsT,
) => {
  switch (action.type) {
    case SettingsActions.SET_SETTINGS:
      return {
        ...state,
        data: action.payload,
      };

    case SettingsActions.RESET:
      return initialState;

    default:
      return state;
  }
};

export default reducer;
