export enum ROUTES {
  HOME = '/home',
  CHECK = '/check',
  PROFILE = '/profile',
  PROFILE_ADD = '/profile/add',
  SETTINGS = '/settings',
  STATISTIC = '/statistic',
  INFO = '/info',
  HELP = '/help',
  CONTACTS = '/contacts',
}
