import AppBarMUI from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import cn from 'classnames';
import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';

import {SettingsIcon, ArrowRightIcon} from '~/assets/icons';
import {DEFAULT_DISTANCE, ROUTES} from '~/constants';
import {IUser, ILabelValue} from '~/types';
import {IReduxStore} from '~/store';
import {setCurrentUserAction} from '~/store/users';
import {getOptions} from '~/services/utils';
import {SelectUser} from '~/components/blocks';
import './appBar.scss';
// import {useTranslation} from 'react-i18next';

interface IOwnProps {
  isDrawerOpen?: boolean;
  toggleDrawerOpen?: () => void;
  className?: string;
}

type AppBarProps = IOwnProps;

const AppBar = ({isDrawerOpen = false, toggleDrawerOpen, className}: AppBarProps): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const {t} = useTranslation();

  const users: IUser[] = useSelector((state: IReduxStore) => state.users?.data);
  const currentUser: Maybe<IUser> = useSelector((state: IReduxStore) => state.users?.current);
  const distance: number = useSelector(
    (state: IReduxStore) => state.settings.data?.distance || DEFAULT_DISTANCE,
  );

  const onAddUser = () => {
    navigate(ROUTES.PROFILE_ADD);
  };

  const onUserSelect = (val: ILabelValue<string>) => {
    const newCurrentUser = users.find((user) => user.id === val.value);
    if (newCurrentUser) {
      dispatch(setCurrentUserAction(newCurrentUser));
    }
  };

  const onProfileSettings = (val: ILabelValue<string>) => {
    navigate(`${ROUTES.PROFILE}/${val.value}/edit`);
  };

  const onSettings = () => {
    navigate(ROUTES.SETTINGS);
  };

  const list = getOptions(users);
  let current: Maybe<ILabelValue<string>> = null;
  if (currentUser) {
    current = {
      value: currentUser.id,
      label: currentUser.name,
    };
  }

  return (
    <AppBarMUI position="fixed" className={cn('app-bar', className)}>
      <Toolbar disableGutters={!isDrawerOpen} className="app-bar-toolbar">
        <IconButton
          color="inherit"
          aria-label="Open drawer"
          onClick={toggleDrawerOpen}
          className={cn('drawer-menu-button', {hide: isDrawerOpen})}
        >
          <ArrowRightIcon />
        </IconButton>
        <Typography variant="h6" color="primary" noWrap className="drawer-title">
          Check.Vision
        </Typography>
        <div className="app-bar-right">
          <SettingsIcon onClick={onSettings} className={'app-bar-btn'} />

          {/* <span className={'app-bar-distance-label'}>{t('common.Distance')}:</span>*/}
          <span className={cn('app-bar-distance-value')}>{`${distance}m`}</span>

          <SelectUser
            current={current}
            list={list}
            onAdd={onAddUser}
            onUserSelect={onUserSelect}
            onSettings={onProfileSettings}
          />
        </div>
      </Toolbar>
    </AppBarMUI>
  );
};

export default AppBar;
