/**
 * @fileOverview Root App component
 */

import React, {Suspense} from 'react';
import {Provider} from 'react-redux';

import i18n from '~/services/i18n';
import {LANGS, LangType} from '~/types';
import {
  // history,
  store
} from './store';
import AppRouter from './AppRouter';

import './app.scss';

const App = (): JSX.Element => {
  const pathname = window?.location?.pathname || `/${LangType.en}`;
  const pathLang = pathname.split('/')[1];
  const currentLang = i18n.language;
  if (LANGS.includes(pathLang) && pathLang !== currentLang) {
    i18n.changeLanguage(pathLang);
  }

  return (
    <Provider store={store}>
      <Suspense fallback={null}>
        <div className="app">
          <AppRouter
          //  history={history}
          />
        </div>
      </Suspense>
    </Provider>
  );
};

export default App;
