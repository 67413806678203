class BaseStorage {
  async get(key: string): Promise<string> {
    return window?.localStorage?.getItem(key) || '';
  }

  async set(key: string, value: string): Promise<void> {
    return window?.localStorage?.setItem(key, value);
  }

  async reset(key: string): Promise<void> {
    return window?.localStorage?.setItem(key, '');
  }

  static browserSupported(): boolean {
    return !!window?.localStorage;
  }
}

const localStorage = new BaseStorage();

export default localStorage;
