import {FC, SVGProps} from 'react';

export enum EyeType {
  LEFT = 'left',
  RIGHT = 'right',
}

export interface SvgName {
  name: string;
  Svg: FC<SVGProps<SVGSVGElement>>;
  className?: string;
}

export interface ICheckSideBtn extends SvgName {
  onClick: () => void;
  isActive?: boolean;
  isDisabled?: boolean;
}

